<template>
    <div>
        <ts-page-title
            :title="$t('productProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('productProfile.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-between">
                    <div class="tw-flex tw-space-x-3">
                        <Button type="info" ghost @click="addNew">
                            {{ $t('addNew') }}
                        </Button>
                        <!-- open back when stock in main apply -->
                        <Button
                            v-if="false"
                            class="ts-capitalize"
                            outline
                            @click.prevent="allSerial"
                        >
                            {{ $t('productProfile.ViewAllSerial') }}
                        </Button>
                    </div>
                    <div class="tw-justify-end tw-space-x-2">
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('productProfile.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                        <ts-button
                            v-tooltip="$t('filter')"
                            @click.prevent="visible = true"
                        >
                            <i class="fa fa-filter"></i>
                        </ts-button>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="overflow-auto">
                <Table
                    :columns="columns"
                    :data="resources"
                    size="small"
                    :loading="loading"
                    max-height="700"
                    stripe
                >
                    <template slot-scope="{ row }" slot="group">
                        {{ row.group ? row.group.pro_group_name_en : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="uom_category">
                        {{
                            row.uom_category
                                ? row.uom_category.uom_cat_name_en
                                : ''
                        }}
                    </template>
                    <template slot-scope="{ row }" slot="vendors">
                        {{ row.vendors ? row.vendors.vendor_name : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="type">
                        {{ row.type ? row.type.product_type_en : '' }}
                    </template>
                    <template slot-scope="{ row }" slot="is_expirable">
                        <span class="badge bg-success" v-if="row.is_expirable"
                            >Yes</span
                        >
                        <span class="badge bg-danger" v-else>No</span>
                    </template>
                    <template slot-scope="{ row }" slot="is_available_for_sale">
                        <i-switch
                            v-model="row.is_available_for_sale"
                            size="small"
                            @on-change="allowForSale(row)"
                        />
                    </template>
                    <template slot-scope="{ row }" slot="is_calculate_stock">
                        <i-switch
                            v-model="row.is_calculate_stock"
                            size="small"
                            @on-change="calculate(row)"
                        />
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <!-- open back when stock in main apply -->
                        <a
                            v-if="row.is_generate_unique_serial && false"
                            href="#"
                            v-tooltip="$t('productProfile.viewSerial')"
                            class="text-primary"
                            @click.prevent="viewSeril(row)"
                        >
                            <i class="fas fa-list-ul"></i>
                        </a>
                        <a
                            href="#"
                            class="text-primary ml-2"
                            @click.prevent="edit(row)"
                            v-tooltip="$t('edit')"
                        >
                            <Icon type="ios-create" size="20" />
                        </a>
                        <Poptip
                            confirm
                            :title="$t('areYouSure')"
                            @on-ok="deleteRecord(row)"
                            :transfer="true"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                        >
                            <a
                                class="ml-2 text-danger"
                                v-tooltip="$t('delete')"
                                :disabled="row._deleting"
                            >
                                <i
                                    class="fas fa-circle-notch fa-spin"
                                    v-if="row._deleting"
                                ></i>
                                <Icon type="ios-trash" size="20" v-else />
                            </a>
                        </Poptip>
                    </template>
                </Table>
            </div>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'profileIndex',
    data () {
        return {
            loading: false,
            showForm: false,
            width: 'width: 300px'
        }
    },
    computed: {
        ...mapState('product/profile', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.product.profile.search
            },
            set (newValue) {
                this.$store.commit('product/profile/SET_SEARCH', newValue)
                this.$store.commit('product/profile/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    title: this.$t('productProfile.barcode'),
                    key: 'barcode',
                    minWidth: 120,
                    sortable: true
                },
                {
                    title: this.$t('productProfile.group'),
                    slot: 'group',
                    minWidth: 120,
                    sortable: true
                },
                {
                    title: this.$t('productProfile.nameEn'),
                    key: 'product_name_en',
                    minWidth: 200
                },
                {
                    title: this.$t('productProfile.nameKh'),
                    key: 'product_name_kh',
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t('productProfile.uomCategory'),
                    slot: 'uom_category',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('productProfile.productType'),
                    slot: 'type',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('productProfile.supplier'),
                    slot: 'vendors',
                    minWidth: 150
                },
                {
                    title: this.$t('productProfile.expirationAvailable'),
                    slot: 'is_expirable',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('productProfile.saleAvailable'),
                    slot: 'is_available_for_sale',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('productProfile.calculateStock'),
                    slot: 'is_calculate_stock',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 90
                }
            ]
        }
    },
    methods: {
        ...mapActions('product/profile', ['saleAvailable', 'calculateStock']),
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('product/profile/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        addNew () {
            this.$router.push({ name: 'product-profile-create' })
        },
        edit (record) {
            this.$store.commit('product/profile/SET_EDIT_DATA', record)
            this.$router.push({
                name: 'product-profile-edit',
                params: { id: record.product_id }
            })
        },
        deleteRecord (record) {
            record._deleting = true
            this.$store
                .dispatch('product/profile/destroy', record.product_id)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        async allowForSale (record) {
            record.loading_sale = true
            let response = await this.saleAvailable(record.product_id)
            record.is_available_for_sale = response.is_available_for_sale
            record.loading_sale = false
        },
        async calculate (record) {
            record.loading_stock = true
            let response = await this.calculateStock(record.product_id)
            record.is_calculate_stock = response.is_calculate_stock
            record.loading_stock = false
        },
        viewSeril (record) {
            this.$router.push({
                name: 'product-profile-serail',
                params: { id: record.product_id }
            })
        },
        allSerial () {
            this.$router.push({ name: 'product-profile-all-serail' })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('product/profile/RESET_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
<style scoped>
.vendor-list {
    display: table;
    border-collapse: collapse;
    border: none;
}
.vendor-list td {
    padding: 2px 4px;
    border-top: none !important;
    font-size: 10px;
}
</style>
