var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('productProfile.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('productProfile.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"tw-flex tw-space-x-3"},[_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t('addNew'))+" ")]),(false)?_c('Button',{staticClass:"ts-capitalize",attrs:{"outline":""},on:{"click":function($event){$event.preventDefault();return _vm.allSerial.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('productProfile.ViewAllSerial'))+" ")]):_vm._e()],1),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('productProfile.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ts-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('filter')),expression:"$t('filter')"}],on:{"click":function($event){$event.preventDefault();_vm.visible = true}}},[_c('i',{staticClass:"fa fa-filter"})])],1)])]),_c('div',{staticClass:"overflow-auto"},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"group",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.group ? row.group.pro_group_name_en : '')+" ")]}},{key:"uom_category",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.uom_category ? row.uom_category.uom_cat_name_en : '')+" ")]}},{key:"vendors",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.vendors ? row.vendors.vendor_name : '')+" ")]}},{key:"type",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.type ? row.type.product_type_en : '')+" ")]}},{key:"is_expirable",fn:function(ref){
        var row = ref.row;
return [(row.is_expirable)?_c('span',{staticClass:"badge bg-success"},[_vm._v("Yes")]):_c('span',{staticClass:"badge bg-danger"},[_vm._v("No")])]}},{key:"is_available_for_sale",fn:function(ref){
        var row = ref.row;
return [_c('i-switch',{attrs:{"size":"small"},on:{"on-change":function($event){return _vm.allowForSale(row)}},model:{value:(row.is_available_for_sale),callback:function ($$v) {_vm.$set(row, "is_available_for_sale", $$v)},expression:"row.is_available_for_sale"}})]}},{key:"is_calculate_stock",fn:function(ref){
        var row = ref.row;
return [_c('i-switch',{attrs:{"size":"small"},on:{"on-change":function($event){return _vm.calculate(row)}},model:{value:(row.is_calculate_stock),callback:function ($$v) {_vm.$set(row, "is_calculate_stock", $$v)},expression:"row.is_calculate_stock"}})]}},{key:"action",fn:function(ref){
        var row = ref.row;
return [(row.is_generate_unique_serial && false)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('productProfile.viewSerial')),expression:"$t('productProfile.viewSerial')"}],staticClass:"text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.viewSeril(row)}}},[_c('i',{staticClass:"fas fa-list-ul"})]):_vm._e(),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(row)}}},[_c('Icon',{attrs:{"type":"ios-create","size":"20"}})],1),_c('Poptip',{attrs:{"confirm":"","title":_vm.$t('areYouSure'),"transfer":true,"ok-text":_vm.$t('yes'),"cancel-text":_vm.$t('no')},on:{"on-ok":function($event){return _vm.deleteRecord(row)}}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('delete')),expression:"$t('delete')"}],staticClass:"ml-2 text-danger",attrs:{"disabled":row._deleting}},[(row._deleting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('Icon',{attrs:{"type":"ios-trash","size":"20"}})],1)])]}}])})],1),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }